<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="section-title pl-2 pt-2 pr-2">
          <span class="font-weight-bolder">{{ $t('stockRemaining') }}</span>

          <div class="d-flex">
            <div class="income-order-search order-search w-100">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal">
                  <input
                    v-model="search"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div>
                </div>
              </div>
            </div>

            <b-form-select
              v-model="selected"
              :options="options"
              class="w-50 ml-1"
              size="sm"
            />
          </div>
        </div>
        <div class="pl-2 pr-2 pt-1">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
          >
            <template #cell(number)="data">
              <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link>
            </template>
            <!-- <template #cell(index)="data">
                      {{ perPage * (currentPage - 1) + (data.index + 1) }}
                    </template> -->
            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="EditType(data.item)"
              />
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end mb-2">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-add-new"
        title="เพิ่มรายการการเงิน"
        ok-title="บันทึก"
        cancel-title="ยกเลิก"
        @ok.prevent="submitAddNew()"
      >
        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Customer Code"
            rules="required"
          >
            <label for="customerCode">{{ $t('customerCode') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="customerCode"
              v-model="customerCode"
              :state="errors.length > 0 ? false:null"
              type="text"
              :placeholder="$t('customerCode')"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Type"
            rules="required"
          >
            <label for="type">{{ $t('type') }} : <span class="text-danger">*</span></label>
            <b-form-select
              v-model="type"
              :options="typeOp"
              :state="errors.length > 0 ? false:null"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="detail">{{ $t('details') }} :</label>
          <b-form-textarea
            id="detail"
            v-model="detail"
            :placeholder="$t('details')"
          />
        </b-form-group>

        <b-form-group>
          <validation-provider
            #default="{ errors }"
            name="Amount"
            rules="required"
          >
            <label for="amount">{{ $t('quantity') }} : <span class="text-danger">*</span></label>
            <b-form-input
              id="amount"
              v-model="amount"
              type="number"
              :state="errors.length > 0 ? false:null"
              :placeholder="$t('quantity')"
            />
            <span class="text-danger">{{ errors[0] }}</span>
          </validation-provider>
        </b-form-group>

        <b-form-group>
          <label for="prefix">Prefix :</label>
          <b-form-input
            id="prefix"
            v-model="prefix"
            type="number"
            placeholder="Prefix"
          />
        </b-form-group>

        <b-form-group>
          <label for="prefixNum">Prefix Number :</label>
          <b-form-input
            id="prefixNum"
            v-model="prefixNum"
            type="number"
            placeholder="Prefix Number"
          />
        </b-form-group>
      </b-modal>
    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink, BFormSelect, BModal, BFormGroup, BFormInput, BFormTextarea, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BPagination,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      items: [],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number', label: this.$t('orderNumber'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'deliveryNumber', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'customer', label: this.$t('productName'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'transactionDate', label: this.$t('productName'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'approvalDate', label: this.$t('details'), sortable: false, thStyle: { width: '35%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'noun', label: this.$t('color'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',
        },
        {
          key: 'document', label: this.$t('size'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customerType', label: this.$t('quantity'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'customerType2', label: this.$t('stockRemaining'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData('all')
  },
  methods: {
    submitAddNew() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const params = {
            customerCode: this.customerCode,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixNum: this.prefixNum,
          }
          this.$http.post('groupbyuser/list/create', params).then(() => {
            this.getData(this.isActive)
            this.$bvToast.toast('เพิ่มข้อมูลสำเร็จ', {
              title: 'สำเร็จ',
              variant: 'success',
              solid: true,
              autoHideDelay: 1500,
            })
          })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    showAddNew() {
      this.$bvModal.show('modal-add-new')
    },
    async getData(type) {
      this.isActive = type
      try {
        const { data: res } = await this.$http.get('/groupbyuser/list/index')
        this.items = res
      } catch (e) {
        console.log(e)
      }
    },
    EditType(data) {
      this.$router.push({ name: 'admin-user-type-edit', params: { id: data.id } })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

                  <style lang="scss" scoped></style>
